import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useLongPress from './useLongPress';
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles(theme => ({
    webPlant: {
        padding: '7px 20px 0px 10px',
        display: 'inline-flex'
    },
    separator: {
        paddingTop: '10px'
    }
}));
function ComicSeries(props) {
    const [seriesSearch, setSeriesSearch] = useState('');
    const [startYear, setStartYear] = useState('');
    // state = {
    //     seriesSearch: '',
    //     startYear: ''
    // };
    const classes = useStyles();

    return (
        <div>
            <div>   <input type="text"
                value={seriesSearch}
                // onChange={(event) =>  this.setState({ seriesSearch: event.target.value })}
                onChange={(event) => setSeriesSearch(event.target.value)}
                placeholder="Series Starts With" />
                <input type="text"
                    value={startYear}
                    onChange={(event) => setStartYear(event.target.value)}
                    placeholder="Start Year" />
                <button
                    onClick={() => props.addSeries(props.hero, seriesSearch, startYear)}
                >Search</button></div>
            <Grid container className={classes.separator}>
                {props.series
                    .sort((a, b) =>
                        b.startYear - a.startYear ||
                        b.modified - a.modified
                    )
                    .map(card => <SeriesLink key={card.id} card={card} hero={props.hero} setCurrentSeries={props.setCurrentSeries} editMode={props.editMode} />)}
            </Grid>
            {/* {props.card.name} */}
            {/* {props.cards.map(card => <Hero key={card.id} card={card} />)}    */}
        </div>
    );

};

// function Series(props){
//    return <div>{props.card.title}</div>
// }

const SeriesLink = (props) => {
    const classes = useStyles();
    return (
        <Grid item className={classes.webPlant}>

            <div className={props.card.gotIt ? "cardwrapper_highlight" : "cardwrapper_nohighlight"}>
                <div>
                    <Link to={props.editMode ? "/comicseries/" : "/series/"}>
                        <Badge badgeContent={((props.card.ownedOfseries === undefined) ? 0 : props.card.ownedOfseries) + ' / ' + props.card.comics.available} color="primary">
                            <img src={props.card.thumbnail.path.replace('http', 'https') + '/portrait_medium.' + props.card.thumbnail.extension} title={props.card.title}
                                onClick={() => props.setCurrentSeries(props.card, props.hero)} />
                        </Badge>
                    </Link>
                    <div>{props.card.startYear}-{props.card.endYear}</div>
                </div>
                {/* <div style={{display: 'inline-block', marginLeft: 10}}> */}
                {/* <div className="comicdesc">
                    {props.card.description}
                </div>
                <div className="comicdesc" style={{ fontSize: '1.25em', fontWeight: 'bold' }}>
                    <Link to={"/series/"} onClick={() => props.setCurrentSeries(props.card)}>{props.card.title}</Link>

                </div> */}

                {/* <div className="item4">
        {props.comics.items.map(comic => <Comic key={comic.name} {...comic}/>)}
        </div> */}

            </div>






        </Grid>
    );
};

export default ComicSeries;