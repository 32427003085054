import React, { Component } from 'react';
import axios from 'axios';
import Heroes from './Heroes'

class Form extends React.Component {
  state = { userName: '' }
  handleSubmit = (event) => {
    event.preventDefault();
    //  var datetime = Date().getDate().getFullYear() + Date().getDate().getMonth() + Date().getDate().getDay() + 'T' + Date().getDate().getHours() + ':' + Date().getDate().getMinutes() + ':00';
    // var hash = datetime + '04355dc174b0d9175dd2163b64283c77d5b21869' + '71baad4ef825a96718c5040630c88fe4';
    //this.props.setProgress(true);
    // axios.get('https://gateway.marvel.com:443/v1/public/characters?name=' + this.state.userName + '&apikey=71baad4ef825a96718c5040630c88fe4' + '&ts=' + datetime + '&hash=' +hash)
    axios.get('https://gateway.marvel.com:443/v1/public/characters?name=' + this.state.userName + '&apikey=71baad4ef825a96718c5040630c88fe4')
      .then(resp => {
        //  this.props.setProgress(false);
        this.props.onSubmit(resp.data.data.results.length ? [{ ...resp.data.data.results[0], source: 'marvel' }] : [{ name: this.state.userName, source: 'custom' }]);
        this.setState({ userName: '' })

      });
  };
  render() {
    return (
      <div style={{ width: '100%' }}>
        <form onSubmit={this.handleSubmit}>
          <input type="text"
            value={this.state.userName}
            onChange={(event) => this.setState({ userName: event.target.value })}
            ref={(input) => this.userNameInput = input}
            placeholder="Hero Name" required />
          <button type="submit">Add Card</button>
        </form>
      </div>
    );
  }
}

class Queryhero extends React.Component {

  // addNewCard = (cardInfo) => {
  //   cardInfo.forEach(element => {
  //     this.setState(prevState => ({
  //       cards: prevState.cards.concat(element)
  //   }));
  //   });
  // };

  render() {
    return (
      <div>
        {this.props.addEnabled && <Form onSubmit={this.props.addNewCard} setProgress={this.props.setProgress} />}
        <Heroes cards={this.props.cards} setCurrentCard={this.props.setCurrentCard} />
      </div>
    );
  }
}
export default Queryhero;
    // ReactDOM.render(<App />, mountNode);


