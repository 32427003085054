import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './CardList.css';


const useStyles = makeStyles(theme => ({
    webPlant: {
        padding: '7px 0px 0px 10px',
        display: 'inline-flex'
    }
}));


const Hero = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:699px)');
    return (
        <Grid item className={classes.webPlant}>
            <Link to={props.editMode ? "/" : "/comicseries/"} onClick={() => props.setCurrentCard(props)}>
                {props.thumbnail && <img src={props.thumbnail.path.replace('http', 'https') + '/portrait_fantastic.' + props.thumbnail.extension} title={props.name} />}
                <div>{props.name}</div>
            </Link>
        </Grid>
    );
};

const Heroes = (props) => {
    return (
        <Grid Container spacing={1}>
            {props.cards.map(card => <Hero key={card.id} {...card} setCurrentCard={props.setCurrentCard} editMode={props.editMode} />)}
        </Grid>
    )
}

export default Heroes;