import React, { Component } from 'react';
import axios from 'axios';
import Queryhero from './Queryhero';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom'
// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import ContainedButtons from './Header'
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from './firebaseconfig';
import useLongPress from './useLongPress';
import Grid from '@material-ui/core/Grid';
import ComicSeries from './ComicSeries'
import Comics from './Comics'

firebase.initializeApp(firebaseConfig);

const uiConfig = {
  signInSuccessUrl: 'false',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign('<your-privacy-policy-url>');
  }
};

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(firebase.auth());
// The start method will wait until the DOM is loaded.
ui.start('#firebaseui-auth-container', uiConfig);

const AuthDiv = (props) => {
  return (
    <div>
      <div id="firebaseui-auth-container">
      </div>
    </div>
  );
}


class App extends React.Component {
  state = {
    currentCard: null,
    currentSeries: null,
    currentComic: null,
    cards: [],
    series: [],
    comics: [],
    comicsIGot: [],
    editMode: false,
    stuffHappening: false,
    addEnabled: true,
    appuser: null
    // ,
    // seriesButton: false,
    // comicsButton:false
  };
  setProgress = (bool) => {
    this.setState({ stuffHappening: { bool } });
  };

  addNewCard = (cardInfo) => {

    cardInfo.forEach(element => {
      this.setState(prevState => ({
        cards: prevState.cards.concat(element)
      }));
      var firestore = firebase.firestore();
      var docRef = firestore.doc("heroes/" + element.name);
      docRef.set(
        { name: element.name, data: element }
      );
      var userData = firestore.doc("users/" + this.state.appuser.email + "/heroes/" + element.name);
      userData.set(
        { name: element.name, data: element }
      );
    });

  };

  setCurrentCard = (card) => {
    if (this.state.editMode) {
      this.deleteHero(card);
    } else {
      this.setState({ currentCard: card }
      );
      this.setState({ comics: [] });
      this.setState({ currentComic: null });

      var firestore = firebase.firestore();
      var docRef = firestore.collection("users/" + this.state.appuser.email + "/heroes/" + card.name + "/series");
      docRef.onSnapshot((querySnapshot) => {
        this.setState({
          series: []
        });
        querySnapshot.forEach((doc) => {
          this.setState(prevState => ({
            series: prevState.series.concat({
              ...doc.data().data, gotIt: true
              , ownedOfseries: prevState.comicsIGot.filter(x => x.seriesId === doc.data().data.id).length

            })
          }));
        });
      });

    };


  };
  addSeries = (hero, name, startYear) => {
    this.setState({ stuffHappening: true });
    let title = name;
    //  axios.get('https://gateway.marvel.com:443/v1/public/characters/' + id + '/series?apikey=71baad4ef825a96718c5040630c88fe4')
    let year = startYear === '' ? '' : '&startYear=' + startYear;
    let url = hero.id ? '/characters/' + hero.id : '';
    axios.get('https://gateway.marvel.com:443/v1/public' + url + '/series?titleStartsWith=' + title + year + '&orderBy=-startYear&limit=99&apikey=71baad4ef825a96718c5040630c88fe4')
      .then(resp => {
        this.setState({ stuffHappening: false });
        resp.data.data.results.forEach(element => {
          this.setState(prevState => ({
            series: prevState.series.concat({ ...element, gotIt: false })
          }));
          //   var firestore = firebase.firestore();
          //   var docRef =firestore.doc("heroes/" + hero.name + "/series/" + element.title);
          //   docRef.set(
          //     {title: element.title , data: element}
          //   );
          //   var userData =firestore.doc("users/" + this.state.appuser.email + "/heroes/"  + name + "/series/" + element.title);
          //   userData.set(
          //     {title: element.title , data: element}
          //   );
        })

      });
  };

  updateSeries = (series, doSomething) => {
    this.setState({ stuffHappening: true });

    //  axios.get('https://gateway.marvel.com:443/v1/public/characters/' + id + '/series?apikey=71baad4ef825a96718c5040630c88fe4')
    axios.get('https://gateway.marvel.com:443/v1/public/series/' + series.id + '?apikey=71baad4ef825a96718c5040630c88fe4')
      .then(resp => {
        // this.setState({series: resp.data.data.results}); 

        this.setState({ stuffHappening: false });

        //resp.data.data.results.forEach(element => {
        this.setState({ currentSeries: resp.data.data.results[0] }, doSomething);

        //   this.setState(prevState => ({
        //     series: prevState.series.concat({...element,gotIt:false})
        // }); 

        //   var firestore = firebase.firestore();
        //   var docRef =firestore.doc("heroes/" + hero.name + "/series/" + element.title);
        //   docRef.set(
        //     {title: element.title , data: element}
        //   );
        //   var userData =firestore.doc("users/" + this.state.appuser.email + "/heroes/"  + name + "/series/" + element.title);
        //   userData.set(
        //     {title: element.title , data: element}
        //   );
        //           })

      });
  };



  deleteHero = (hero) => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      var firestore = firebase.firestore();
      var userData = firestore.doc("users/" + this.state.appuser.email + "/heroes/" + hero.name);

      userData.collection("series")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (series) {
            this.deleteSeriesExecute(series, hero).then(function () {
              console.log("Series successfully deleted!");
            }).catch(function (error) {
              console.error("Error removing series: ", error);
            });
          });
        });
      userData.delete().then(function () {
        console.log("Document successfully deleted!");
      }).catch(function (error) {
        console.error("Error removing document: ", error);
      });

    }
  };

  deleteSeries = (card, hero) => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.deleteSeriesExecute(card, hero);
    }
  };


  deleteSeriesExecute = (card, hero) => {
    var firestore = firebase.firestore();
    var userData = firestore.doc("users/" + this.state.appuser.email + "/heroes/" + hero.name + "/series/" + card.title);

    userData.collection("comics")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (comic) {
          comic.ref.delete().then(function () {
            console.log("Document successfully deleted!");
          }).catch(function (error) {
            console.error("Error removing document: ", error);
          });
        });
      });
    userData.delete().then(function () {
      console.log("Document successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
  };

  setCurrentSeries = (card, hero) => {
    if (this.state.editMode) {
      if (card.gotIt) {
        this.deleteSeries(card, hero);
      } else {
        this.setState({
          series: this.state.series.map(el => (el.id === card.id ? Object.assign({}, el, { gotIt: true }) : el))
        });
        //  this.setState(prevState => ({
        //       series: prevState.series.concat({...card,gotIt:true})
        //   })); 
        this.addAllComics(card);
      }
    } else {
      this.getCurrentSeries(card, hero);
    }

  };

  updateSeriesImage = (newImage) => {
    this.setState(prevState => ({
      currentSeries: {
        ...prevState.currentSeries,
        thumbnail: {
          ...prevState.currentSeries.thumbnail,
          path: newImage
        }
      }
    }))
  };

  getCurrentSeries = (card, hero) => {
    this.setState({ currentSeries: card }
      // this.setState({comics: []}),
      // this.addComics(card.id)
    );
    this.setState({ currentComic: null });

    var firestore = firebase.firestore();
    var docRef = firestore.collection("users/" + this.state.appuser.email + "/heroes/" + hero.name + "/series/" + card.title + "/comics");
    docRef.onSnapshot((querySnapshot) => {


      this.setState({
        comics: []
      });
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.setState(prevState => ({
          comics: prevState.comics.concat({ ...doc.data().data, gotIt: this.state.comicsIGot.some(x => x.id == doc.data().data.id) })
        }));
      });
    });
    // alert('length: ' + this.state.comics.length);
    // alert('avail: ' + card.comics.available);
    if (this.state.comics.length < card.comics.available) {
      // alert("insideIf");
      this.addAllComics(card);
    }


  };

  addAllComics = (series) => {
    this.updateSeries(series, () => {
      const hero = this.state.currentCard;
      const allBooks = series.comics.available;
      const take = 99;
      for (let currentSkip = 0; currentSkip < allBooks; currentSkip = currentSkip + take) {
        this.addComics(this.state.currentSeries, hero, take, currentSkip);
      }
    }
    );

  };

  addComics = (series, hero, take = 99, skip = 0) => {
    //  const hero = (heroPass === null ? this.state.hero : heroPass) || this.state.hero;
    this.setState({ stuffHappening: true });
    //  axios.get('https://gateway.marvel.com:443/v1/public/characters/' + id + '/series?apikey=71baad4ef825a96718c5040630c88fe4')
    //https://gateway.marvel.com:443/v1/public/series/27108/comics?limit=99&apikey=71baad4ef825a96718c5040630c88fe4
    axios.get('https://gateway.marvel.com:443/v1/public/series/' + series.id + '/comics?limit=' + take + '&offset=' + skip + '&apikey=71baad4ef825a96718c5040630c88fe4')
      .then(resp => {
        this.setState({ comics: resp.data.data.results.map((row) => { return { ...row, gotIt: this.state.comicsIGot.some(x => x.id == row.id) } }) });
        this.setState({ stuffHappening: false });
        let variantComics = resp.data.data.results.filter(x => x.title.includes('(Variant)')).length;
        resp.data.data.results.filter(x => !x.title.includes('(Variant)')).forEach(element => {
          var firestore = firebase.firestore();
          var seriesImageName = series.thumbnail.path.split('/').pop();
          var seriesImage = element.issueNumber === 1 && seriesImageName == 'image_not_available' ? element.thumbnail.path : series.thumbnail.path;
          var serRef = firestore.doc("heroes/" + hero.name + "/series/" + series.title);
          serRef.set(
            { title: series.title, data: { ...series, thumbnail: { ...series.thumbnail, path: seriesImage }, comics: { ...series.comics, available: series.comics.available - variantComics } } }
          );
          var userSerData = firestore.doc("users/" + this.state.appuser.email + "/heroes/" + hero.name + "/series/" + series.title);
          userSerData.set(
            { title: series.title, data: { ...series, thumbnail: { ...series.thumbnail, path: seriesImage }, comics: { ...series.comics, available: series.comics.available - variantComics } } }
          );

          var docRef = firestore.doc("heroes/" + hero.name + "/series/" + series.title + "/comics/" + element.issueNumber);
          docRef.set(
            { issue: element.issueNumber, title: element.title, data: element }
          );
          var userData = firestore.doc("users/" + this.state.appuser.email + "/heroes/" + hero.name + "/series/" + series.title + "/comics/" + element.issueNumber);
          userData.set(
            { issue: element.issueNumber, title: element.title, data: element }
          );
        })




      });
  };

  updateIGotStatus = function (id, gotIt) {
    this.setState({
      comics: this.state.comics.map(el => (el.id === id ? Object.assign({}, el, { gotIt }) : el))
    });

    var firestore = firebase.firestore();
    var docRef = firestore.doc("users/" + this.state.appuser.email + "/comics/" + id);
    if (gotIt) {
      docRef.set({ id: id, seriesId: this.state.currentSeries.id, heroId: this.state.currentCard.id ? this.state.currentCard.id : 0 });
    }
    else {
      docRef.delete();
    }
  }

  setCurrentComic = (card) => {
    if (this.state.editMode) {
      if (this.state.comicsIGot.some(x => x.id == card.id)) {
        this.setState(prevState => (
          { comicsIGot: prevState.comicsIGot.filter(x => x.id !== card.id) }
        ), this.updateIGotStatus(card.id, !card.gotIt));
      } else {
        this.setState(prevState => (
          { comicsIGot: prevState.comicsIGot.concat({ id: card.id, seriesId: prevState.currentSeries.id, heroId: prevState.currentCard.id ? prevState.currentCard.id : 0 }) }
        ), this.updateIGotStatus(card.id, !card.gotIt));
      }

    } else {
      this.setState({ currentComic: card });
    }
  };
  toggleEditMode = () => {
    this.setState(prevState => ({
      editMode: !prevState.editMode
    }), this.setState({ currentComic: null }))
  };
  setUser = (user) => {

    this.setState({ appuser: user })
    var firestore = firebase.firestore();

    var userData = firestore.doc("users/" + this.state.appuser.email);
    userData.set(
      { email: this.state.appuser.email }
    );

    var docRef = firestore.collection("users/" + this.state.appuser.email + "/heroes/");
    docRef.onSnapshot((querySnapshot) => {
      this.setState({
        cards: []
      });
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.setState(prevState => ({
          cards: prevState.cards.concat(doc.data().data)
        }));
      });
    });

    var comicRef = firestore.collection("users/" + this.state.appuser.email + "/comics/");
    comicRef.onSnapshot((querySnapshot) => {
      this.setState({
        comicsIGot: []
      });
      querySnapshot.forEach((doc) => {
        this.setState(prevState => ({
          comicsIGot: prevState.comicsIGot.concat(doc.data())
        }));
      });
    });
  };

  render() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && !this.state.appuser) {
        // User is signed in.
        this.setUser(user);

      } else {
        // User is signed out.
        // ...
      }
    });
    return (
      <div>
        {!this.state.appuser && <AuthDiv setUser={this.setUser} />}
        {this.state.appuser &&
          <Router>

            <ContainedButtons showSeries={this.state.series.length} showComics={this.state.comics.length} toggleEditMode={this.toggleEditMode} editMode={this.state.editMode} />
            {this.state.stuffHappening && <CircularProgress />}

            <Route path="/" exact
              render={(props) => <Queryhero cards={this.state.cards} addNewCard={this.addNewCard} setCurrentCard={this.setCurrentCard} setProgress={this.setProgress} addEnabled={this.state.addEnabled}
                editMode={this.state.editMode} />}
            />
            {this.state.currentCard !== '' && <Route path="/comicseries/"
              render={(props) => <ComicSeries hero={this.state.currentCard} series={this.state.series} setCurrentSeries={this.setCurrentSeries}
                addSeries={this.addSeries} longPressSeriesCallback={this.deleteSeries} editMode={this.state.editMode} />}
            />}
            {this.state.currentCard !== '' && <Route path="/series/"
              render={(props) => <Comics comics={this.state.comics} currentSeries={this.state.currentSeries} addAllComics={this.addAllComics} setCurrentComic={this.setCurrentComic} showSelected={this.state.currentComic !== null} currentComic={this.state.currentComic} updateSeriesImage={this.updateSeriesImage} />}
            />}
            <Route path="*" exact>
              <Redirect to="/" />
            </Route>
          </Router>}
      </div>
    )
  };
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
export default App;
    // ReactDOM.render(<App />, mountNode);


