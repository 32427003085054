import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useLongPress from './useLongPress';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
    webPlant: {
        padding: '10px 10px 5px 5px',
        display: 'inline-flex'
    },
    separator: {
        paddingTop: '10px'
    }
}));


const Comics = (props) => {

    return (
        <div>
            {props.showSelected && <ComicBig {...props.currentComic} updateSeriesImage={props.updateSeriesImage} />}
            <Grid container>
                {props.comics
                    .sort((a, b) => {
                        if (b.issueNumber > a.issueNumber) { return -1; }
                        if (b.issueNumber < a.issueNumber) { return 1; }
                        return 0;
                    })
                    .map(card => <Comic key={card.id} {...card} setCurrentComic={props.setCurrentComic} />)}
            </Grid>
        </div>
    );
};

const Comic = (props) => {
    const classes = useStyles();
    return (
        <Grid item className={classes.webPlant}>

            <div className={props.gotIt ? "cardwrapper_highlight" : "cardwrapper_nohighlight"}>
                <Badge badgeContent={props.issueNumber + props.variantDescription.charAt(0)} color="primary">
                    <img src={props.thumbnail.path.replace('http', 'https') + '/portrait_medium.' + props.thumbnail.extension} title={props.title} onClick={() => props.setCurrentComic(props)} />
                </Badge>

                {/* <div style={{display: 'inline-block', marginLeft: 10}}> */}
                {/* <div className="item4">
        {props.comics.items.map(comic => <Comic key={comic.name} {...comic}/>)}
        </div> */}

            </div>




            {/* <Link to={"/series/"} onClick={() => props.setCurrentSeries(props.card)}>{props.card.title}</Link> */}
            {/* {props.title} */}
        </Grid>
    );
};

const ComicBig = (props) => {
    return (
        <div>
            <div className="herowrapper">
                <div>
                    <img width="150" src={props.thumbnail.path.replace('http', 'https') + '/portrait_xlarge.' + props.thumbnail.extension} title={props.title} />
                </div>
                <div>
                    {props.description}
                </div>
                <div className="comictitle">
                    {props.title} {props.gotIt ? " *GOT IT!* " : ""}
                </div>
            </div>
        </div>
    );
};

export default Comics;