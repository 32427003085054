import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));
function EnableSeries() {

}

export default function ContainedButtons(props) {
  const classes = useStyles();

  return (
    <div>
       
            
            <Link to="/" style={{ textDecoration: 'none' }} >
            <Button size="small" variant="contained" color="primary" className={classes.button}>Heroes</Button>
               </Link>
            <Link to={props.showComics ? "/comicseries/" : ""} style={{ textDecoration: 'none' }}>
            <Button size="small" variant="contained" disabled={!props.showSeries} color="primary" className={classes.button}>Series</Button>
               </Link>
            <Link to={props.showComics ? "/series/" : ""} style={{ textDecoration: 'none' }}>
            <Button size="small" variant="contained" disabled={!props.showComics} color="primary" className={classes.button}>Comics</Button>
               </Link>
            <Button size="small" variant="contained" disabled={!props.showComics && !props.showSeries} color={props.editMode ? "secondary" : "primary"} className={classes.button} onClick={props.toggleEditMode}>{props.editMode ? "Edit" : "View"}</Button>
           
        
        
      {/* <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" className={classes.button}>
          Upload
        </Button>
      </label> */}
    </div>
  );
}